import React from 'react'
import styled from 'styled-components'
import App from 'App'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { colors, fontSizes, space, radius, fontWeights, COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Flex } from 'components/Layout'
import Container from 'components/Container'
import { H1 } from 'components/Typography'
import Footer from 'components/Footer'
import BreadCrumbs from 'components/BreadCrumbs'
import GetStartedSection from './GetStartedSection'

import { blogContentAddingJoinTemplate } from './helper'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`

const ArticleBody = styled.div`
  max-width: 730px;
  color: #231f20;
  figure {
    margin: ${space.m} 0;
    padding: ${space.s} 0;
    text-align: center;
    border-radius: ${radius.l};
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: ${radius.l};
  }
  font-size: ${fontSizes.xl};
  a {
    color: ${colors.primary};
    font-weight: ${fontWeights.medium};
  }
  h2 {
    margin-top: ${space.xl};
    margin-bottom: ${space.m};
    padding: 0 0 ${space.s} 0;
    color: ${COLOR_CONSTANTS.DENIM};
  }
  h3 {
    margin-top: ${space.l};
    margin-bottom: ${space.m};
    padding: ${space.m} 0 ${space.s} 0;
  }
`

const StyledTextWrapper = styled.div``

export const query = graphql`
  query($article: String) {
    wordpressPost(slug: { eq: $article }) {
      title
      content
      excerpt
      date
      author {
        name
        avatar_urls {
          wordpress_96
        }
      }
      categories {
        slug
        name
      }
      featured_media {
        title
        alt_text
        caption
        source_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`

// TODO:: please create a new category, that would be not included on our site, I will filter it out in gatsby-node.js. Also we could take the title and description as meta information
const CaptionGenerator = ({ data, pageContext: { name, description, path } }) => {
  const { wordpressPost } = data || {}
  const { content } = wordpressPost || {}

  const updatedContent = blogContentAddingJoinTemplate({ content })

  return (
    <App fullHeader>
      <SEO title={name} description={description} />

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex width="100%" justifyContent="center" flexDirection="column" py="xl">
            <Flex
              alignItems="center"
              display={{ mobile: 'none', mobileLarge: 'flex' }}
              justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
            >
              <BreadCrumbs
                data={[
                  { label: 'Vista Social', path: '/' },
                  { label: 'Free Tools', path: '/social-media-tools/' },
                  { label: 'Glossary', path: '/social-media-tools/glossary/' },
                  { label: name, path },
                ]}
              />
            </Flex>
            <Flex mt="l" flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
              <H1
                fontSize="4xl"
                fontWeight="bold"
                color={COLOR_CONSTANTS.DENIM}
                textAlign={{ mobile: 'center', desktop: 'left' }}
              >
                <H1 as="span" fontSize="4xl" fontWeight="bold" textAlign={{ mobile: 'center', desktop: 'left' }}>
                  {name}
                </H1>
              </H1>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      {updatedContent && (
        <Wrapper my="xl" bg={COLOR_CONSTANTS.WHITE}>
          <Container
            pl={{ mobile: 'm', tablet: 'l' }}
            pr={{ mobile: 'm', tablet: 'l' }}
            position="relative"
            maxWidth={CONTAINER_MAX_WIDTH}
            width="100%"
            height="100%"
          >
            <Flex
              mx="auto"
              pl={{ mobile: 'l', tablet: 'l' }}
              pr={{ mobile: 'l', tablet: 'l' }}
              flexDirection="column"
              alignItems="center"
              position="relative"
              maxWidth="1024px"
              width="100%"
            >
              <ArticleBody className="post">
                <StyledTextWrapper dangerouslySetInnerHTML={{ __html: updatedContent }} />
              </ArticleBody>
            </Flex>
          </Container>
        </Wrapper>
      )}

      <GetStartedSection />

      <Footer />
    </App>
  )
}

CaptionGenerator.propTypes = {
  pageContext: PropTypes.shape({
    network: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  data: PropTypes.object.isRequired,
}

export default CaptionGenerator
